<script setup lang='ts'>
import { APP_ROUTER, checkLink, imageTransform } from '@qctsw/common'
import { getCurrentTime, numFormat } from '@qctsw/utils'
import { logicHomeBanner } from '@/logic/home/banner'
import { logicNewCompleteComplaintList } from '@/logic/home/newCompleteComplaintList'
import { AD_KEY } from '~/server/types'
import adStore from '@/stores/ad'

const keys = {
  complainTotal: '投诉',
  totalCollective: '集体',
  totalMore: '多次投诉',

  complainMoney: '涉诉金额',
  exchangeCarTotal: '退换车',
}

const { adList } = storeToRefs(adStore())
const adData = computed(() => adList.value.get(AD_KEY.A4) || [])
const {
  state,
  bannerData,
  getHomeBannerData,
} = logicHomeBanner()

const {
  list,
  getNewCompleteComplaintList,
  transformArea,
} = logicNewCompleteComplaintList()

await Promise.all([
  getHomeBannerData(),
  getNewCompleteComplaintList(),
])
const currentYear = computed(() => getCurrentTime('YYYY'))
</script>

<template>
  <UtilLoadState :state="state" :reload-handler="getHomeBannerData">
    <div class="mt-2xl w-full bg-white rounded shadow border-1 border-gray-1 py-3 flex items-center justify-around">
      <div v-for="text, key in keys" :key="key" class="text-5.5 items-center relative">
        <p>
          <span>{{ text }}</span>
          <span class="text-orange-6 text-6 font-bold pl-2">{{ numFormat(key === 'complainMoney' ? bannerData?.complaint?.[key] * 10000 : bannerData?.complaint?.[key]) }}{{ bannerData?.complaint?.[key] >= 9999 ? '+' : '' }}</span>
          <span>{{ key === 'complainMoney' ? '元' : '宗' }}</span>
        </p>
        <p v-if="key === 'complainMoney'" class=" absolute  right-0  text-3 color-slate">
          {{ currentYear }}年 ~ 至今
        </p>
      </div>
      <NuxtLink :to="APP_ROUTER.complaintSearch()" class="bg-orange-6 text-white px-4xl py-2 rounded-full text-5.5 hover:bg-orange-5">
        投诉查询
      </NuxtLink>
    </div>
    <div v-if="list?.length" class="flex items-center justify-start mt-sm bg-gradient-to-tr from-orange-1 rounded px-sm py-1">
      <div class="flex-shrink-0 flex items-center text-orange-6">
        <i i-ph:chat-teardrop-dots-bold />
        <span class="text-warning mr-1.25 ml-0.5">最新解决:</span>
      </div>
      <NCarousel
        direction="vertical" :show-dots="false" show-arrow :autoplay="true" mousewheel
        class="pr-3 relative flex-grow min-w-0 h-6"
      >
        <NuxtLink
          v-for="v in list" :key="v.complainId"
          :to="APP_ROUTER.complaintDetail(v.complainId)"
          class="flex items-center"
        >
          <span class="truncate" style="flex-shrink: 1;">{{ v.title }}</span>
          <span class="flex-shrink-0 font-bold text-orange-6">
            <span v-if="v.undoFinishTime">({{ v.isUndo ? '已撤帖' : '撤帖公示中' }})</span>
            <span v-else>{{ `(${v.complainScoreCode}分)` }}</span>
          </span>
          <span v-if="v?.label" class="flex-shrink-0 ">
            <span class="text-orange-6 ml-2.5 mr-1.25">诉求:</span>
            <span>{{ v.label }}</span>
          </span>
          <span class="flex-shrink-0 ">
            <span class="text-orange-6 ml-2.5 mr-1.25">投诉地区:</span>
            <span>{{ transformArea(v) || '' }}</span>
          </span>
        </NuxtLink>
        <template #arrow="{ prev, next }">
          <button class="absolute top-0 h-full right-0 flex flex-col items-center justify-center text-2.5">
            <i i-el:caret-up @click="prev" />
            <i i-el:caret-down @click="next" />
          </button>
        </template>
      </NCarousel>
    </div>
    <BasicTitle class="mb-sm mt-xl" title="首页聚焦" />
    <div class="flex items-start">
      <NCarousel
        show-arrow dot-type="line" effect="fade"
        :default-index="0" :autoplay="true"
        class="flex-shrink-0 h-90 w-162.5 rounded-md mr-sm bg-slate-1"
      >
        <!-- TODO:AD_A4 -->
        <!-- <BaseAd v-if="useAd(AD_KEY.A4)" :ad-key="AD_KEY.A4" :img-height="540" :img-width="975" body-class="my-0" /> -->

        <NuxtLink
          v-for="item, index in adData" :key="index"
          class="flex items-center justify-end h-full relative"
          :to="item.linkUrl"
        >
          <img
            class="h-full w-full object-cover"
            :alt="item.title"
            :src="imageTransform(item.imgUrl, { width: 975, height: 540 })"
          >
          <h3 class="title">
            {{ item.title }}
          </h3>
        </NuxtLink>

        <NuxtLink
          v-for="item, index in bannerData.banner" :key="index"
          class="flex items-center justify-end h-full relative"
          :to="checkLink(item.link, APP_ROUTER.newsDetail(item.id))"
        >
          <img
            class="h-full w-full object-cover"
            :alt="item.title"
            :src="imageTransform(item.coverImgUrl, { width: 975, height: 540, format: 'jpeg' })"
          >
          <h3 class="title">
            {{ item.title }}
          </h3>
        </NuxtLink>
      </NCarousel>
      <div v-if="bannerData.news" class="flex-grow min-w-0 text-4">
        <NuxtLink
          :to="checkLink(bannerData.news[0]?.link, APP_ROUTER.newsDetail(bannerData.news[0]?.id))" no-rel
          class="block truncate mb-2 px-3 py-1 border-1 border-red-5 text-red-5 rounded hover:border-red-6 hover:text-red-6"
        >
          {{ bannerData.news[0]?.title }}
        </NuxtLink>
        <NuxtLink
          v-for="(v, i) in bannerData.news.slice(1)" :key="i"
          :to="checkLink(v.link, APP_ROUTER.newsDetail(v.id))"
          class="block truncate  mb-1.25 last:mb-0 hover:text-orange-6 hover:text-orange-6)"
        >
          {{ v.title }}
        </NuxtLink>
      </div>
    </div>
  </UtilLoadState>
</template>

<style lang='scss' scoped>
@import url(./banner.scss);
</style>
